// Here you can add other styles
    // Colors
.s-bg-color{
    background-color: $secondary-base !important;
}
.p-bg-color{
  background-color: $primary-base !important;
}
.p-color{
  color: $primary-base!important;
}
.s-color{
  color: $secondary-base!important;
}
.white{
  color: white
}

.sidebar-nav .nav-link {
    font-size: large;
    color: #ebedef;
}

.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link:hover{
    color: white;
    // background: #00366c7a;
    background: $primary-dark;
}

.sidebar-nav .nav-link.active .nav-icon,
.sidebar-nav .nav-link:hover .nav-icon {
    color: white;
}

.sidebar-nav .nav-group.show{
  background-color: #146c4785;
}

.popover {
  max-width: min-content !important;
}

.imagepicker-preview {
  position: relative;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #c4c9d0;
  border-radius: 5px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}