//     // For Google Chrome
//  &::-webkit-scrollbar {
//     width:  5px;
//     height: 5px;
// }

// &::-webkit-scrollbar-thumb {
//   background: $foreground-color;
// }

// webkit-scrollbar-track {
//   background: $background-color;
// }

//    // For Internet Explorer
// & {
//   scrollbar-face-color: $foreground-color;
//   scrollbar-track-color: $background-color;
// }

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bbb; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa; 
  }
